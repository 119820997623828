"use client";
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import CopyBoxToClipboard from "./CopyBoxToClipboard";

import { CouponsObj } from "@/app/interfaces/Interfaces";

import { useDisclosure } from "@chakra-ui/react";
import { sendGTMEvent } from "@next/third-parties/google";

interface CouponModalProps {
  coupon_obj: CouponsObj;
}
const removeQueryParam = (paramKey: string) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  searchParams.delete(paramKey);
  url.search = searchParams.toString();
  window.history.replaceState({}, document.title, url.href);
};

const CouponModal = React.memo(({ coupon_obj }: CouponModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = React.useCallback(() => {
    onOpen();
    removeQueryParam("coupon_id");
    sendGTMEvent({
      event: "coupon_clicked",
      coupon: coupon_obj,
    });
  }, [onOpen, coupon_obj]);

  React.useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  const handleClick = React.useCallback(() => {
    window.open(coupon_obj.gotolink, "_blank");
  }, [coupon_obj.gotolink]);

  return (
    <Modal
      blockScrollOnMount
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={true}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={""}>{coupon_obj.coupon_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontWeight="light" mb="1rem">
              1. Скопируйте промокод.
            </Text>
            <CopyBoxToClipboard
              promocode={coupon_obj.promocode ?? "НЕ НУЖЕН"}
            />
            <Text fontWeight="light" mb="1rem">
              2. Перейдите на сайт магазина.
            </Text>
            <Text fontWeight="light" mb="1rem">
              3. Активируйте купон.
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={"center"}>
          <Button
            height="43px"
            width="100%"
            px="8px"
            borderRadius="5px"
            fontSize="14px"
            bg="#f5dd42"
            color="black"
            _hover={{ bg: "#f5ce42" }}
            _active={{ bg: "#f5ce42", transform: "scale(0.98)" }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
            onClick={handleClick}
          >
            {"Перейти в магазин"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

CouponModal.displayName = "CouponModal";
export default CouponModal;
