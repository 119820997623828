"use client";
import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";

import "../css/embala.css";
import { useDotButton } from "./EmblaCarouselDotButtons";
import imageLoader from "./ImageLoader";
import { Banners } from "../interfaces/Interfaces";
import { Link } from "@chakra-ui/react";

interface Props {
  slides: Banners[];
}

const EmblaCarousel = ({ slides }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ playOnInit: true, delay: 7000 }),
  ]);
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <section
      style={{
        backgroundColor: `${slides[selectedIndex].accompanying_color}`,
        transform: "translate3d(0px, 0px, 0px)",
        transitionDuration: "2s",
      }}
    >
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((slide, index) => (
              <div className="embla__slide" key={index}>
                <Link href={`${slide.deep_link}`}>
                  <Image
                    loader={imageLoader}
                    src={`${slide.banner_image}`}
                    width={600}
                    height={400}
                    alt={`Спецальное предложение от ${slide.banner_name}`}
                    placeholder="blur"
                    quality={50}
                    blurDataURL={`${slide.accompanying_color}`}
                    priority
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default EmblaCarousel;
