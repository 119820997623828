"use client";
import { Card, Text, CardBody, Link, Box } from "@chakra-ui/react";
import React from "react";
import { MerchantObj } from "../interfaces/Interfaces";
import Image from "next/image";
import imageLoader from "../components/ImageLoader";
interface Props {
  merchant: MerchantObj;
}

const MainMerchantCard = React.memo(({ merchant }: Props) => {
  const handleClick = React.useCallback(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "merchant_click",
      merchant_id: merchant.merchant_id,
      merchant_name: merchant.merchant_name,
    });
  }, [merchant]);

  return (
    <Card bgColor="white" padding="20px">
      <Link href={`/merchants/${merchant.slug}`} onClick={handleClick}>
        <Box width="140px" height="40px">
          <Image
            loader={imageLoader}
            src={merchant.image_url}
            alt={`Промокоды и акции от ${merchant.merchant_name}`}
            width={140}
            height={40}
            unoptimized
          />
        </Box>
        <CardBody>
          <Text as="b" fontSize="1xl" noOfLines={1}>
            {merchant.merchant_name}
          </Text>
        </CardBody>
      </Link>
    </Card>
  );
});
MainMerchantCard.displayName = "MainMerchantCard";
export default MainMerchantCard;
