"use client";
import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";

const CopyBoxCurrentURL = () => {
  const [copied, setCopied] = useState(false);
  const pathname = usePathname();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText("https://rupromokod.ru" + pathname);
      sendGTMEvent({
        event: "share_link",
        location: "https://rupromokod.ru" + pathname,
      });
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 5000); // Скрыть сообщение через 10 секунд
    } catch (error) {
      console.error("Ошибка при копировании текста:", error);
    }
  };

  return (
    <Button onClick={copyToClipboard} w={"300px"}>
      {copied && "Текст скопирован!"}

      {!copied && "Скопировать ссылку на страницу"}
    </Button>
  );
};

export default CopyBoxCurrentURL;
