"use client";
import React, { useState } from "react";
import { Box, Center, Text, HStack, Flex } from "@chakra-ui/react";
import { FaRegCopy } from "react-icons/fa";

interface Props {
  promocode: string;
}

const CopyBoxToClipboard = ({ promocode }: Props) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      const textToCopy = promocode;
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 10000); // Скрыть сообщение через 10 секунд
    } catch (error) {
      console.error("Ошибка при копировании текста:", error);
    }
  };

  return (
    <Flex
      gap={2}
      w={"100%"}
      minW="200px"
      height={"50px"}
      background="lightgrey"
      cursor="pointer"
      onClick={copyToClipboard}
      justify={"center"}
      align={"center"}
    >
      <FaRegCopy />
      <Text fontWeight="light">
        {copied && "Текст скопирован!"}

        {!copied && promocode}
      </Text>
    </Flex>
  );
};

export default CopyBoxToClipboard;
