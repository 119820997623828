"use client";
import {  Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { MerchantCouponsInterface, CouponsObj } from "../interfaces/Interfaces";

interface Props {
  coupon_data: MerchantCouponsInterface | CouponsObj;
}

const SaleButton = React.memo(({ coupon_data }: Props) => {

  const redirect_user = React.useCallback(() => {
    // Open popup window
    if (typeof window !== "undefined") {
      const { coupon_id, promocode, discount, gotolink } = coupon_data;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "coupon_impression",
        coupon_id: coupon_id,
        coupon_name: promocode,
        coupon_value: discount,
      });

      window.open(gotolink, "_blank");
    }
  }, [coupon_data]);

  return (
    <Link width={{ base: "100%", md: "207px" }}>
      <Flex
        width="100%"
        style={{
          position: "relative",
          background: "#FDDE55",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          height: "44px",
          border: "1px dashed #FDDE55",
          borderRadius: "8px",
        }}
        onClick={redirect_user}
      >
        <Text color="#333">Получить скидку</Text>
      </Flex>
    </Link>
  );
});

SaleButton.displayName = 'SaleButton';

export default SaleButton;
