"use client";
import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";

import { MerchantCouponsInterface, CouponsObj } from "../interfaces/Interfaces";

interface Props {
  coupon_data: MerchantCouponsInterface | CouponsObj;
}

const CouponButton = ({ coupon_data }: Props) => {
  const [revealed, setRevealed] = useState(false);

  const revealCode = () => {
    setRevealed(true);
    // Open popup window
    if (typeof window !== "undefined") {
      let currentPageUrl = window.location.href;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "coupon_impression",
        coupon_id: coupon_data.coupon_id,
        coupon_name: coupon_data.promocode,
        coupon_value: coupon_data.discount,
      });

      window.open(
        `${currentPageUrl}?coupon_id=${coupon_data.coupon_id}`,
        "_blank" // <- This is what makes it open in a new window.
      );
    }
  };

  return (
    <>
      <Link href={coupon_data.gotolink} width={{ base: "100%", md: "207px" }}>
        <Flex
          className="but"
          width={"100%"}
          style={{
            position: "relative",
            background: "#FEEFAD",
            alignItems: "center",
            overflow: "hidden",
            height: "44px",
            border: "1px dashed #FEEFAD",
            borderRadius: "8px",
          }}
          onClick={revealCode}
        >
          <Flex
            style={{
              display: "block",
              background:
                "linear-gradient(-104deg, transparent 20px, #FDDE55 0)",
              borderRadius: "8px",
              transition: "all 1s",
              position: "absolute",
              zIndex: 10,
              width: "100%",
              top: 0,
              color: "black",
              overflow: "hidden",
              padding: "10px",

              left: 0,
              height: "44px",
            }}
            _after={{
              content: "''",
              position: "absolute",
              top: 0,
              right: 0,
              width: "0",
              height: "0",
              transform: "translateY(-1px) translateX(-8px) rotate(-14deg)",
              transformOrigin: "bottom right",
              borderBottomLeftRadius: "inherit",
              transition: "transform 0.05s ease",
              borderTop: "12px solid transparent",
              borderRight: "14px solid #FCFFE0",
              borderBottom: "33px solid transparent",
            }}
            _hover={{
              shadow: "md",
              transform: "translateX(-10px)",
              transitionDuration: ".4s",
              transitionTimingFunction: "cubic-bezier(0, 0, 0.4, 1)",
              _after: {
                transitionDuration: ".4s",
                transitionTimingFunction: "cubic-bezier(0, 0, 0.4, 1)",
                transform: "translateY(-1px) translateX(-8px) rotate(-14deg)",
              },
            }}
          >
            <Text color={"#333"}>Показать промокод</Text>
          </Flex>
          <Flex justify={"end"} align={"end"} paddingRight="8px" w={"100%"}>
            <Text color={"#333"}>
              {coupon_data.promocode
                ? coupon_data.promocode.slice(-3)
                : "НЕ НУЖЕН".slice(-3)}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </>
  );
};

export default CouponButton;
