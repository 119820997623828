"use client";
import {
  Flex,
  Text,
  Button,
  Tabs,
  TabPanels,
  TabList,
  Center,
} from "@chakra-ui/react";

import React, { ReactNode, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";

interface Props {
  itemCount: number;
  pageSize: number;
  data: ReactNode;
}

const TabPagination = ({ itemCount, pageSize, data }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const pageCount = Math.ceil(itemCount / pageSize);

  if (itemCount < 1) return null;
  return (
    <Flex marginBottom={10}>
      <Tabs index={tabIndex} onChange={handleTabsChange} variant="unstyled">
        <TabPanels>{data}</TabPanels>
        <Center marginTop={4}>
          {pageCount > 1 && (
            <TabList>
              <Flex gap={2}>
                <Button
                  leftIcon={<MdKeyboardArrowLeft color="black" />}
                  variant="link"
                  isDisabled={tabIndex === 0}
                  onClick={() => setTabIndex(tabIndex - 1)}
                  color={"black"}
                >
                  Назад
                </Button>
                <Text size={"2"} marginX={2}>
                  стр. {tabIndex + 1} из {pageCount}
                </Text>
                <Button
                  rightIcon={<MdKeyboardArrowRight color="black" />}
                  variant="link"
                  isDisabled={tabIndex === pageCount - 1}
                  onClick={() => setTabIndex(tabIndex + 1)}
                  color={"black"}
                >
                  Вперед
                </Button>
              </Flex>
            </TabList>
          )}
        </Center>
      </Tabs>
    </Flex>
  );
};

export default TabPagination;
